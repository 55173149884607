import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function updateUserProfileMetadata(user) {
    const { displayName, jobTitle, photoURL, skills } = user
    return mutate(
        gql`
            mutation UpdateUserProfileMetadata(
                $displayName: String!
                $jobTitle: String
                $photoURL: String
                $skills: [String]
            ) {
                updateUserProfileMetadata(
                    displayName: $displayName
                    jobTitle: $jobTitle
                    photoURL: $photoURL
                    skills: $skills
                )
            }
        `,
        { displayName, jobTitle, photoURL, skills },
        { returns: "updateUserProfileMetadata" }
    )
}
