import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"
import { registerOfflineAction } from "lib/@offline/register-offline-action"

const completeGql = api(async (shareLinkId, taskId, metadata, completionDate = Date.now()) =>
    mutate(
        gql`
            mutation API_CompleteTask($shareLinkId: String!, $completions: [TaskCompletion!]!) {
                completeSharedTask(shareLinkId: $shareLinkId, completions: $completions)
            }
        `,
        { shareLinkId, completions: [{ taskId, completionDate, metadata }] },
        { returns: "completeSharedTask" }
    )
)

export const completeTask = registerOfflineAction(completeGql, "completeTask")
