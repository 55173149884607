import { BoundAutocomplete } from "lib/@components/binding/bound-components"
import words from "library/three-words/words.json"

export function Word(props) {
    return (
        <BoundAutocomplete
            filterOptions={startsWith}
            options={words}
            autoComplete
            autoHighlight
            autoSelect
            {...props}
        />
        // <BoundTextField {...props} />
    )

    function startsWith(options, state) {
        const value = state.inputValue.toLowerCase()
        return options.filter((o) => o.toLowerCase().startsWith(value))
    }
}
