import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const payloadFromWords = api(async function payloadFromWords(words) {
    return query(
        gql`
            query GetPayloadFromWords($words: String!) {
                payloadFromWords(words: $words)
            }
        `,
        { words },
        { returns: "payloadFromWords", fetchPolicy: "network-only" }
    )
})
