import Page from "minimals-template/components/Page"
import { StretchContainer } from "lib/@components/stretch-container"
import { useMobile } from "lib/@components/mobile"
import useIsXsScreen from "minimals-template/components/@hooks/useIsXsScreen"

export function PageContainer({ title, children, ...props }) {
    const isMobile = useMobile()

    const isXsScreen = useIsXsScreen()
    return (
        <Page
            title={title}
            sx={{
                pl: isMobile ? 0 : undefined,
                pr: isMobile ? 0 : undefined,
                ml: isXsScreen ? 1.75 : undefined,
                mr: isXsScreen ? 1.75 : undefined,
            }}
        >
            <StretchContainer {...props}>{children}</StretchContainer>
        </Page>
    )
}
