import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function setSignature(signature) {
    return mutate(
        gql`
            mutation SetSignature($signature: String!) {
                setSignature(signature: $signature)
            }
        `,
        { signature },
        { returns: "setSignature" }
    )
}
