import { HasInvalidated } from "event-definitions"
import useAsync from "lib/@hooks/useAsync"
import { areTasksComplete } from "companion/routes/schedule/controller/are-tasks-complete"
import { createEvent } from "library/local-events"
import { useEffect } from "react"
import { Loading } from "lib/use-tasks"

export const completed = Symbol("completed")

export const CompletionsUpdate = createEvent("CompletionsUpdated")

export function useTaskCompletions(shareLinkId, tasks) {
    const refresh = HasInvalidated("areTasksComplete").useRefresh()
    useEffect(() => {
        refresh()
    }, [refresh, tasks])
    return useAsync(getCompletions, null, refresh.id)

    async function getCompletions() {
        if (tasks[Loading]) return []
        const completions = await areTasksComplete(
            shareLinkId,
            new Date().beginningOfDay(),
            Object.keys(tasks).map((id) => ({ taskId: id.split("-")[0] }))
        )
        Object.values(tasks).forEach((task, index) => (task[completed] = completions[index]))
        CompletionsUpdate.raiseOnce()
        return Object.values(tasks).filter((t) => !!t[completed])
    }
}
