import useAsync from "lib/@hooks/useAsync"
import storage from "localforage"
import { getIntroductions } from "companion/routes/schedule/controller/get-introductions"
import { HasInvalidated } from "event-definitions"

export function useIntroductions() {
    const refresh = HasInvalidated("introductions").useRefresh()
    return useAsync(retrieveIntroduction, [], refresh.id)

    async function retrieveIntroduction(update) {
        let introductions = (await storage.getItem("@@introductions")) ?? {}
        update(introductions)

        try {
            introductions = await getIntroductions()

            await storage.setItem("@@introductions", introductions)
        } catch (e) {
            setTimeout(() => retrieveIntroduction(update), 1000 * 60 * 5)
        }
        update(introductions)
        return introductions
    }
}
