import { HttpLink, split } from "@apollo/client"
import { getMainDefinition } from "@apollo/client/utilities"
import { BatchHttpLink } from "@apollo/client/link/batch-http"

const UseHttpLink = window.Cypress ? HttpLink : BatchHttpLink

const httpLinkMain = new UseHttpLink({
    credentials: "include",
    batchInterval: 75,
    batchMax: 20,
    uri: (operation) =>
        `${window.appProtocol || process.env.REACT_APP_PROTOCOL}://${
            window.appServerLocation || process.env.REACT_APP_SERVER
        }/graphql?o=${operation.operationName}${process.env.COMPANION ? "&companion=true" : ""}`,
})

const httpLinkApi = new UseHttpLink({
    batchInterval: 50,
    credentials: "include",
    uri: (operation) =>
        `${window.appProtocol || process.env.REACT_APP_PROTOCOL}://${
            window.appServerLocation || process.env.REACT_APP_SERVER
        }/v3.0?o=${operation.operationName}${process.env.COMPANION ? "&companion=true" : ""}`,
})

export const httpLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return !definition.name.value.startsWith("API_")
    },
    httpLinkMain,
    httpLinkApi
)
