import { returnValue } from "library/return-value"
import noop from "lib/noop"
import { useMemo, useRef } from "react"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useFieldUpdated } from "lib/@hooks/use-field-updated"
import { nanoid } from "nanoid"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { CommitChange, FieldUpdate, ModifyYupError } from "event-definitions"
import { resolveValue } from "lib/resolve-as-function"
import { ensureArray } from "lib/ensure-array"
import { set } from "lib/set"
import * as yupLibrary from "yup"

import { makeLabelFrom } from "lib/@components/binding/make-label-from"

export function Bind({
    input,
    transformIn = returnValue,
    transformOut = returnValue,
    transformOutOnBlur = returnValue,
    defaultValue,
    sxOwn,
    className = "",
    sxBase,
    noLabel = false,
    onBlur = noop,
    sideEffects = false,
    sideEffectsOnBlur = false,
    updateValueOnBlur = false,
    changeOnBlur,
    field,
    yup,
    yupTransform = (v) => v,
    omit = [],
    typeError,

    onChanged = noop,
    valueProp = "value",
    onChangeProp = "onChange",
    extract = (v) => {
        if (v && v.target) {
            return v.target.value
        }
        return v
    },
    ...props
}) {
    const blurred = useRef(true)
    const { field: contextField, readOnly, errors = {}, yupContext } = useBoundContext()
    field = field || contextField
    if (noLabel) {
        props.label = undefined
    }
    defaultValue = defaultValue ?? props.default ?? ""
    const [value] = useFieldUpdated(field, defaultValue)
    const currentValue = useRef()
    const { onChange, target, refresh } = useBoundContext()
    const myId = useMemo(() => nanoid(), [])
    const localRefresh = useRefresh(onChange)
    const editedRefresh = useRefresh(noChange)
    const own = field?.includes(".") ? true : target ? Object.prototype.hasOwnProperty.call(target, field) : false
    const current = useRef()
    CommitChange.useEvent(handleCommit)
    current.current = value
    currentValue.current = transformIn(value) ?? resolveValue(defaultValue)
    FieldUpdate(field).useEvent((value, owner) => {
        if (owner === target) {
            if (current.current !== value) {
                editedRefresh()
            }
        }
    })
    if (typeError && typeof yup !== "string") {
        throw new Error("You must specify yup as a string when using typeError")
    }

    if (yupContext && yup) {
        yupContext[myId] = validate
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isValid = useMemo(() => validate(), [value])

    const error = yup && isValid

    useMemo(() => {
        if (error) {
            errors[myId] = error
        } else {
            delete errors[myId]
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, myId, contextField, errors])

    const inputProps = {
        error: !!error,
        helperText: error?.message,
        id: myId,
        sx: own ? sxOwn : sxBase,
        ...input.props,
        ...props,
        ...{
            [valueProp]: currentValue.current,
            [onChangeProp]: handleChange,
        },
    }
    ensureArray(omit).forEach((p) => delete inputProps[p])

    // set(target, field, value)
    return (
        <input.type
            field={field}
            onBlur={handleBlur}
            {...inputProps}
            className={`${inputProps.className || ""} ${className} bind ${field?.replaceAll(".", "-")}`}
        />
    )

    function handleCommit() {
        if (readOnly) return
        if (updateValueOnBlur || transformOutOnBlur !== returnValue) {
            const value = transformOutOnBlur(currentValue.current)
            set(target, field, value)
        }
        if (sideEffectsOnBlur || input.props.sideEffectsOnBlur) {
            refresh()
        } else {
            localRefresh()
        }
    }

    function handleChange(...params) {
        if (readOnly) return
        const value = transformOut(extract(...params))
        set(target, field, value)
        onChanged(value)
        FieldUpdate(field).raiseOnce(value, target)
        if (sideEffects || input.props.sideEffects) {
            refresh(changeOnBlur ? noChange : undefined)
        } else {
            localRefresh(changeOnBlur ? noChange : undefined)
        }
    }

    function handleBlur(...params) {
        if (readOnly) return
        blurred.current = true
        if (updateValueOnBlur || transformOutOnBlur !== returnValue) {
            const value = transformOutOnBlur(extract(...params))
            set(target, field, value)
        }
        if (sideEffectsOnBlur || input.props.sideEffectsOnBlur) {
            refresh()
        } else {
            localRefresh()
        }
        onBlur(...params)
    }

    function validate() {
        let yupFn
        if (typeof yup === "string") {
            const parsed = yup
                .split(".")
                .map((part) => (!part.includes(")") ? `${part}()` : `${part}`))
                .join(".")
            // eslint-disable-next-line no-new-func
            yupFn = new Function(
                "value",
                "yup",
                `
                    return yup.${parsed}${typeError ? `.typeError("${typeError}")` : ""}.validateSync(value)
                `
            )
        } else {
            yupFn = yup
        }
        try {
            yupFn(yupTransform(value), yupLibrary)
            return null
        } catch (e) {
            let error = { message: e.message, stack: e.stack }
            if (error.message.startsWith("this")) {
                error.message = error.message.slice(5).capitalize()
            }
            ;({ error } = ModifyYupError.call({ error, field, value }))

            return error
        }
    }
}

/**
 * Used to create a bound component from a standard component
 * @param {JSX.Element} input - the component to wrap
 * @param {Function} onProps - a callback that is sent the props and can modify them
 * @param {object} options - options including `onProps` for customisation
 * @returns {function(*)}
 */
export function bind(
    input,
    {
        onProps = (props) => ({
            ...props,
            label: props.label ?? makeLabelFrom(props.field),
        }),
        ...options
    } = {}
) {
    return function bind(props) {
        props = onProps(props)
        return <Bind {...options} {...props} input={input} />
    }
}
