import "./schedule.scss"
import { companionAppRoute } from "companion/routes/companion-app-route"
import { ScheduleLookup } from "companion/routes/schedule/schedule-lookup"
import { ChoosePath } from "companion/routes/schedule/choose-path"
import { Bound } from "lib/@components/binding/Bound"
import { TaskInfo } from "companion/routes/tasks/task-info"

companionAppRoute.register("schedule/:word1/:word2/:word3/details", <ScheduleLookup />)
companionAppRoute.register(
    "embedded/schedule/:word1/:word2/:word3/details",
    <Bound embedded>
        <ScheduleLookup />
    </Bound>
)
companionAppRoute.register("schedule/:word1/:word2/:word3", <ChoosePath />)
companionAppRoute.register("tasks/:word1/:word2/:word3", <TaskInfo />)
