import { deserializeCircular, serializeCircular } from "library/serialize-circular"
import localforage from "localforage"
import { debounce } from "lib/debounce"
import { generate } from "library/guid"
import { createEvent } from "library/local-events"

const CACHED_ACTIONS = "@@cachedActions"
const offlineActions = {}
let cachePromise = Promise.resolve(true)

const OfflineResult = createEvent("OfflineResult")

export function registerOfflineAction(fn, name = fn.name) {
    offlineActions[name] = fn
    return cacheAction

    function cacheAction(...params) {
        console.log("Take action", name)
        const id = generate()
        cachePromise = cachePromise.finally(() => addActionToList(id, ...params).catch(console.error))
        return new Promise((resolve) => {
            OfflineResult(id).once(resolve)
        })
    }

    async function addActionToList(id, ...params) {
        const existing = (await localforage.getItem(CACHED_ACTIONS)) ?? []
        existing.push({ id, name, params: serializeCircular(params) })
        await localforage.setItem(CACHED_ACTIONS, existing)
        performActions()
    }
}

export const performActions = debounce(runActions, 250, { maxWait: 5000 })

async function runActions() {
    cachePromise = cachePromise.finally(async function () {
        const actions = (await localforage.getItem(CACHED_ACTIONS)) ?? []

        const executed = new Set(
            (
                await Promise.all(
                    actions.map(async (action) => {
                        try {
                            const result = await performOfflineAction(
                                action.name,
                                ...deserializeCircular(action.params)
                            )
                            OfflineResult(action.id).raise(result)
                            return action
                        } catch (e) {
                            console.error(e)
                            return null
                        }
                    })
                )
            ).filter(Boolean)
        )

        await localforage.setItem(
            CACHED_ACTIONS,
            actions.filter((a) => !executed.has(a))
        )
    })
}

if (window) {
    window.addEventListener("visibilitychange", runActions)
}

export function performOfflineAction(name, ...params) {
    return (offlineActions[name] || reportError)(...params)

    function reportError() {
        console.error("Offline action", name, "not found", ...params)
    }
}
