export function arrayMoveInPlace(array, previousIndex, newIndex) {
    if (newIndex >= array.length) {
        let k = newIndex - array.length

        while (k-- + 1) {
            array.push(undefined)
        }
    }
    const removed = array.splice(previousIndex, 1)[0]
    array.splice(newIndex, 0, removed)
    return array
}
