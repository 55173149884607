import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const scheduleFromWords = api(async function scheduleFromWords(words) {
    return query(
        gql`
            query GetScheduleFromWords($words: String!) {
                scheduleFromWords(words: $words) {
                    schedule: rawSchedule
                    taskId
                    noLicenseAvailable
                    reason
                    introductions
                }
            }
        `,
        { words },
        { returns: "scheduleFromWords", fetchPolicy: "network-only" }
    )
})
