import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const regimeGuid = api(async (shareLink) =>
    query(
        gql`
            query API_RegimeGuid($shareLink: String!) {
                regimeGuid(shareLinkId: $shareLink)
            }
        `,
        { shareLink },
        { returns: "regimeGuid" }
    )
)
