/**
 * Returns a copy of an object with its properties in a stable order
 * @param {Record<string, any>} object
 * @returns {Record<string, any>}
 */
exports.normalize = normalize

function normalize(object, seen = new Set()) {
    if (!(typeof object === "object") || !object) return object
    if (seen.has(object)) return null
    seen.add(object)
    if (Array.isArray(object)) {
        const output = []
        for (let i = 0, l = object.length; i < l; i++) {
            output.push(normalize(object[i], seen))
        }
        return output
    }
    return Object.fromEntries(
        Object.entries(object)
            .sortBy("0")
            .map(([key, value]) => [key, normalize(value, seen)])
    )
}

/**
 * Stringifies an object with its properties in a stable order
 * @param {Record<string, any>} object
 * @param replacer
 * @returns {string}
 */
exports.normalizedStringify = function normalizedStringify(object, replacer) {
    return JSON.stringify(normalize(object), replacer)
}

function onlyStandardProperties(item, omit = () => false) {
    return exports.normalizedStringify(
        item,
        (k, v) => omit(k) || (k.startsWith("$") || k.startsWith("_") ? undefined : v)
    )
}

exports.onlyStandardProperties = onlyStandardProperties
