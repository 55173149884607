import { useParams } from "react-router-dom"
import { payloadFromWords } from "companion/routes/schedule/controller/payload-from-words"
import { FETCH } from "lib/graphql/api"
import { Bound } from "lib/@components/binding/Bound"
import { Tasks } from "companion/routes/tasks/tasks"

export function TaskInfo() {
    const { word1, word2, word3 } = useParams()
    const { accessToken, shareLinkId, loading } = payloadFromWords.useResults(`${word1} ${word2} ${word3}`, FETCH) ?? {
        loading: true,
    }

    return (
        !loading && (
            <Bound accessToken={accessToken} shareLinkId={shareLinkId}>
                <Tasks />
            </Bound>
        )
    )
}
