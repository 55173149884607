import { useReferenceState } from "lib/@hooks/use-reference"

import { completed, CompletionsUpdate } from "companion/routes/schedule/use-task-completions"

export function completedToday(lastCompleteTime) {
    if (!lastCompleteTime) return false
    return Date.now() - +Date.create(lastCompleteTime).beginningOfDay() < 1000 * 60 * 60 * 24
}

export function useCompleted(task) {
    CompletionsUpdate.useRefresh()
    const [lastCompleteTime] = useReferenceState(`completed-${task.id.toString().split("-")[0]}`, 0)
    return task[completed] || completedToday(lastCompleteTime)
}
