import PropTypes from "prop-types"
import { forwardRef } from "react"
import { Helmet } from "react-helmet-async"
// @mui
import { Box } from "@mui/material"
import { BusyAdd, BusyRemoved, Navigate } from "event-definitions"
import { generate } from "library/guid"
import { delay } from "lib/delay"
import { NotInPdf } from "lib/@components/pdf/not-in-pdf"
import { InPdf } from "lib/@components/pdf/in-pdf"

// ----------------------------------------------------------------------

const Page = forwardRef(function Page({ children, title = "", meta, ...other }, ref) {
    return (
        <>
            <Helmet>
                {title ? <title>{`${title} | Facilities-iQ`}</title> : <title>SFG20</title>}
                {meta}
            </Helmet>
            <NotInPdf>
                <Box
                    {...other}
                    ref={ref}
                    flex={1}
                    sx={{
                        ...other.sx,
                        overflowY: "auto",
                        overflowX: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        height: 1,
                    }}
                >
                    {children}
                </Box>
            </NotInPdf>
            <InPdf>
                <Box
                    ref={ref}
                    flex={1}
                    sx={{
                        overflowX: "hidden",
                    }}
                >
                    {children}
                </Box>
            </InPdf>
        </>
    )
})

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    meta: PropTypes.node,
}

export default Page

Navigate.before.handleOnce(async () => {
    const id = generate()
    BusyAdd.raise({ id })
    await delay(200)
    setTimeout(() => {
        BusyRemoved.raise({ id })
    }, 150)
})
