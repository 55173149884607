const { deriveGuidFrom } = require("library/guid")
const { onlyStandardProperties } = require("library/normalized-stringify")

class MapPlus extends Map {
    constructor(missingFunction) {
        super()
        this.missingFunction = missingFunction
    }

    get(key, createIfMissing = this.missingFunction) {
        let result = super.get(key)
        if (!result && createIfMissing) {
            result = createIfMissing(key)
            if (result && result.then) {
                const promise = result.then((value) => {
                    super.set(key, value)
                    return value
                })
                super.set(key, promise)
            } else {
                super.set(key, result)
            }
        }
        return result
    }
}

class SetAlike extends Set {
    constructor(list = [], mapper = standardMap) {
        super()
        this.mapper = mapper
        list.forEach((i) => this.add(i))
    }

    add(item) {
        super.add(this.mapper(item))
    }

    has(item) {
        return super.has(this.mapper(item))
    }

    delete(item) {
        return super.delete(this.mapper(item))
    }
}

function standardMap(item) {
    return deriveGuidFrom(onlyStandardProperties(item))
}

function createMapper(transform = (v) => v) {
    return (item) => standardMap(transform(item))
}

module.exports = { MapPlus, SetAlike, createMapper }
