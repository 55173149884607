import { Box, ListItemButton, ListItemText, Stack, Typography } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { COLOR_FROM_CLASSIFICATION, ICON_FROM_CLASSIFICATION } from "library/SFG20/constants"
import { alpha } from "@mui/material/styles"
import uniqolor from "uniqolor"
import { ListItemBox } from "lib/@components/ListItemBox"
import { TreeListPage } from "slot-definitions"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { TaskDetails } from "companion/routes/tasks/task-details"
import { Watermark } from "lib/@components/watermark/watermark"
import { useCompleted } from "companion/routes/schedule/use-completed"

TreeListPage.plug(replaceAllWith(<TaskDetails if={({ current }) => current.steps} />))

export function TreeSchedule({ item }) {
    const { go } = useBoundContext()
    const completed = useCompleted(item)

    return (
        <ListItemButton onClick={() => go(item)} sx={{ position: "relative" }}>
            {completed && (
                <Watermark
                    text="Completed"
                    sx={{
                        opacity: 1,
                        bgcolor: "#fff7",
                        backdropFilter: "blur(5px)",
                        textAlign: "center",
                        transform: "translate(100%, 50%) rotate(-15deg) scale(0.4)",
                    }}
                    color="error.main"
                />
            )}
            <ListItemBox spacing={1}>
                <Box>
                    <Iconify
                        icon={ICON_FROM_CLASSIFICATION[item.classification]}
                        sx={{ color: COLOR_FROM_CLASSIFICATION[item.classification], fontSize: 20 }}
                    />
                </Box>
                <ListItemText
                    secondaryTypographyProps={{ component: "div" }}
                    primary={item.title}
                    secondary={
                        <Stack spacing={0}>
                            <Box>
                                {item.schedule.title} #{item.schedule.code}
                            </Box>
                            <Box sx={{ opacity: 0.7 }}>
                                {item.skill.Skilling}: Every {item.frequency.interval}{" "}
                                {item.frequency.period.pluralize(item.frequency.interval)}
                            </Box>
                            <Box sx={{ opacity: 0.5 }}>{item.where}</Box>
                        </Stack>
                    }
                />
                <Box>
                    <Typography variant="body2" component="div">
                        {item.frequency.interval}
                        {item.frequency.period[0]}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        borderRadius: 1,
                        py: 0.5,
                        px: 1.5,
                        bgcolor: alpha(uniqolor(item.skill.SkillingCode).color, 0.3),
                    }}
                >
                    <Typography variant="body2" component="div">
                        {item.skill.SkillingCode}
                    </Typography>
                </Box>
            </ListItemBox>
        </ListItemButton>
    )
}
