import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useTasks } from "lib/use-tasks"
import { useReferenceState } from "lib/@hooks/use-reference"
import { useEffect, useMemo } from "react"
import { treeModes } from "companion/routes/tasks/tree-by"
import { Bound } from "lib/@components/binding/Bound"
import { TreeList, TreeListUser } from "companion/routes/tasks/tree-list"
import { StandardSelector } from "lib/@components/combos/standard-selector"
import { useObjectSearchParam, useSearchParam } from "lib/@hooks/use-search-param"
import { useTaskCompletions } from "companion/routes/schedule/use-task-completions"
import { NoResults } from "lib/@components/no-results/no-results"
import { Box, Button } from "@mui/material"
import { navigate } from "lib/routes/navigate"
import { ListItemBox } from "lib/@components/ListItemBox"

let lastDisplayMode

function logFailure(displayMode) {
    return () => {
        console.error(displayMode, "not valid")
        return {}
    }
}

export function Tasks() {
    const { shareLinkId } = useBoundContext()
    const [displayMode, setDisplayMode] = useReferenceState("displayMode", "skill")
    const [, setCurrentId] = useSearchParam("current")
    const [, setStack] = useObjectSearchParam("stack", [])
    const allTasks = useTasks(shareLinkId, 1, 49)
    const completions = useTaskCompletions(shareLinkId, allTasks)
    console.log({ allTasks, completions })
    // eslint-disable-next-line
    const tree = useMemo(
        () =>
            allTasks.loading
                ? (treeModes[displayMode] ?? logFailure(displayMode))({})
                : (treeModes[displayMode] ?? logFailure(displayMode))(allTasks),
        [displayMode, allTasks]
    )
    useEffect(() => {
        if (lastDisplayMode !== displayMode && lastDisplayMode) {
            setCurrentId(0)
            setStack([])
        }
        lastDisplayMode = displayMode
    }, [displayMode, setCurrentId, setStack])

    const selector = useMemo(
        () => (
            <StandardSelector
                options={[
                    { label: "Skill", value: "skill" },
                    { label: "Skill/Classification", value: "skillClassificationInLocation" },
                    { label: "Location", value: "where" },
                    { label: "Classification", value: "classification" },
                ]}
                onChange={setDisplayMode}
                value={displayMode}
            />
        ),
        [setDisplayMode, displayMode]
    )
    console.log({ tree })
    return !!Object.keys(allTasks).length && !Object.isEmpty(tree) ? (
        <Bound tree={tree} key={displayMode} shareLinkId={shareLinkId}>
            <TreeList tree={tree} />
            <TreeListUser>{selector}</TreeListUser>
        </Bound>
    ) : (
        <>
            <ListItemBox mr={2}>
                <Box flex={1} />
                {selector}
            </ListItemBox>
            <NoResults>No user checks</NoResults>
            <Button onClick={() => navigate("/sfg20")} sx={{ mx: 5, mt: 3 }} variant="contained" color="primary">
                Lookup Another
            </Button>
        </>
    )
}
