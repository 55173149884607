import { Bound } from "lib/@components/binding/Bound"
import { useRefresh } from "lib/@hooks/useRefresh"

import { Frag } from "lib/@components/slot/frag"

const keys = new WeakMap()
let repeatId = 0

function getKey(item) {
    if (Array.isArray(item) && item[0]) return getKey(item[0])
    if (item?.id) return item.id
    if (item?._id) return item._id
    if (!!item && typeof item === "object") {
        const key = keys.get(item) ?? repeatId++
        keys.set(item, key)
        return key
    }
    return item
}

function Simple({ item }) {
    return <div>{typeof item === "object" ? JSON.stringify(item) : item}</div>
}

export function Repeat({
    collection = [],
    list = collection,
    children,
    item = children?.type ? children : <Simple />,
    itemFn = () => false,
    pass = "item",
    keyFn = getKey,
    fallback = <Frag />,
    bind,
}) {
    const refresh = useRefresh()

    if (list instanceof Error) {
        console.warn("Attempting to iterate over an error", list)
        return null
    }

    if (!Array.isArray(list) && typeof list === "object" && list) {
        list = Object.entries(list)
    }

    return !list?.length
        ? fallback
        : list?.map((iterated, index, ar) => {
              const key = keyFn(iterated, index)
              if (bind) {
                  return (
                      <Bound key={key} target={iterated}>
                          {itemFn(iterated, index, ar) || (
                              <item.type
                                  remove={() => remove(iterated)}
                                  {...{
                                      ...item.props,
                                      [pass]: iterated,
                                      previous: ar[index - 1],
                                      index,
                                      last: list.length === index + 1,
                                  }}
                              />
                          )}
                      </Bound>
                  )
              }
              return (
                  itemFn(iterated, index, ar) || (
                      <item.type
                          remove={() => remove(iterated)}
                          key={key}
                          {...{
                              ...item.props,
                              [pass]: iterated,
                              previous: ar[index - 1],
                              next: ar[index + 1],
                              index,
                              last: list.length === index + 1,
                          }}
                      />
                  )
              )
          })

    function remove(item) {
        const idx = list.indexOf(item)
        if (idx >= 0) {
            list.splice(idx, 1)
            refresh()
        }
    }
}
