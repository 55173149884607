import { Box, ListSubheader } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { TaskRowEnd, TaskRowStart } from "slot-definitions"
import { BoundEditableText } from "lib/@components/editable/editableText"
import { Bound } from "lib/@components/binding/Bound"
import { TaskRow } from "routes/schedule/tasks/taskRow"

export function TaskHeader({ item }) {
    return (
        <Bound task={item}>
            <TaskRow item={item}>
                <ListItemBox className="task-header">
                    <TaskRowStart.Slot />
                    <Box flex={1} mt={0.3} className="task-header">
                        <ListSubheader>
                            <BoundEditableText field="title" pt={0} pb={0} />
                        </ListSubheader>
                    </Box>
                    <TaskRowEnd.Slot />
                    <Box width={22} />
                </ListItemBox>
            </TaskRow>
        </Bound>
    )
}
