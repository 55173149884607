const div = document.createElement("div")

/**
 * Strips all of the tags from HTML and returns the text content
 * @param {string} html - the html to strip
 * @returns {string}
 */
export function htmlToText(html) {
    if (!html || html === "undefined") return ""
    div.innerHTML = html
    return div.innerText.trim()
}
