import { Avatar, Box, Button, CardActionArea, CardActions, CardContent, Stack, Typography } from "@mui/material"
import { BoundImageUploadCard } from "lib/@components/image-upload-input"
import Iconify from "minimals-template/components/Iconify"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

export function AccountPhoto() {
    const [image, setImage] = useBoundValue("photoURL")

    return (
        <Stack>
            <BoundImageUploadCard field="photoURL" maxHeight={512}>
                <CardActionArea>
                    <CardContent
                        sx={{
                            height: image ? 300 : 350,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: "50%",
                                borderWidth: "1px",
                                borderStyle: "dashed",
                                borderColor: "grey.50032",
                                padding: 1,
                            }}
                        >
                            <Avatar
                                sx={{
                                    width: 128,
                                    height: 128,
                                    ...(image && {
                                        backgroundImage: `linear-gradient(rgba(22, 28, 36, 0.8), rgba(22, 28, 36, 0.8)), url(${image})`,
                                    }),
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Iconify sx={{ color: "common.white" }} icon="ic:baseline-add-a-photo" />
                                <Typography sx={{ color: "common.white" }} variant="caption">
                                    {image ? "Update photo" : "Add photo"}
                                </Typography>
                            </Avatar>
                        </Box>

                        <Typography mt={4} variant="caption">
                            Allowed *.jpeg *.jpg *.png *.gif
                        </Typography>
                    </CardContent>
                </CardActionArea>
                {image && (
                    <CardActions
                        sx={{ display: "flex", justifyContent: "center" }}
                        onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                        }}
                    >
                        <Button
                            id="editAccountRemovePhotoButton"
                            variant="text"
                            sx={{ color: "error.main" }}
                            startIcon={<Iconify icon="ic:round-delete" />}
                            onMouseDown={(event) => event.stopPropagation()}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                handleRemovePhoto()
                            }}
                        >
                            Remove photo
                        </Button>
                    </CardActions>
                )}
            </BoundImageUploadCard>
        </Stack>
    )

    function handleRemovePhoto() {
        setImage("")
    }
}
