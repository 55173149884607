import { useRef, useState } from "react"
import { Box, Button, Stack } from "@mui/material"
import { DialogBox } from "lib/@components/dialogBox"
import SignaturePad from "react-signature-canvas"
import { ListItemBox } from "lib/@components/ListItemBox"
import { dataURItoBlob } from "lib/data-uri-to-blob"
import { defer } from "lib/defer"
import { uploadFiles } from "lib/@components/uploadButton"
import { setSignature } from "plugins/user/controller/set-signature"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { useCurrentUser } from "lib/store/use-current-user"

export function SignatureDialog() {
    const ref = useRef()
    const [signature, setSignatureUrl] = useState()
    const [loading, setLoading] = useState()
    const user = useCurrentUser()
    return (
        <Box
            sx={{
                "& .sign": {
                    width: "100%",
                    height: "40vh",
                    bgcolor: "white",
                    color: "#333",
                    borderRadius: 1,
                },
            }}
        >
            <DialogBox title="Create Your Signature" value={signature}>
                <Stack
                    spacing={2}
                    sx={{
                        pointerEvents: loading ? "none" : undefined,
                        transition: "opacity 0.3s linear",
                        opacity: loading ? 0.3 : 1,
                    }}
                >
                    <Box />
                    <SignaturePad minWidth={1.1} maxWidth={3.5} ref={ref} canvasProps={{ className: "sign" }} />
                    <ListItemBox spacing={1}>
                        <Button variant="contained" onClick={autoSign}>
                            Use Your Name
                        </Button>
                        <Box flex={1} />
                        <Button variant="contained" onClick={adopt}>
                            Adopt
                        </Button>
                        <Button color="secondary" onClick={() => ref.current.clear()} size="small">
                            Clear
                        </Button>
                    </ListItemBox>
                </Stack>
            </DialogBox>
        </Box>
    )

    function autoSign() {
        const canvas = ref.current.getCanvas()
        const ctx = canvas.getContext("2d")

        ctx.clearRect(0, 0, canvas.width, canvas.height)

        // Set the font, size, and style
        ctx.font = `${(canvas.width * 0.9) / (user.metadata.displayName.length + 4)}px "Homemade Apple", cursive`
        ctx.fillStyle = "black"

        // Draw the text
        ctx.fillText(user.metadata.displayName, 10, 90)
        return adopt()
    }

    async function adopt() {
        try {
            setLoading(true)
            const canvas = ref.current.getTrimmedCanvas()
            if (canvas.width < 79) {
                errorSnackbar("Signature too small")
                return
            }
            const image = dataURItoBlob(canvas.toDataURL("image/png"))

            const promise = defer()
            // noinspection ES6MissingAwait
            uploadFiles(image, promise.resolve)
            const url = await promise
            setSignatureUrl(url)
            await setSignature(url)
        } finally {
            setLoading(false)
        }
    }
}
