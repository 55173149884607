import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const regime = api(async (shareLink, changesSince, minimumInterval, maximumInterval) =>
    query(
        gql`
            query API_GetRegimeTasks(
                $shareLink: String!
                $changesSince: Date
                $minimumInterval: Int
                $maximumInterval: Int
            ) {
                regime(
                    shareLinkId: $shareLink
                    changesSince: $changesSince
                    minimumInterval: $minimumInterval
                    maximumInterval: $maximumInterval
                ) {
                    guid
                    tasks {
                        ... on APITask {
                            __typename
                            _status
                            intervalInHours
                            title
                            id
                            url
                            linkId
                            frequency {
                                interval
                                period
                            }
                            skill {
                                Skilling
                                SkillingCode
                                CoreSkillingID
                            }
                            schedule {
                                title: rawTitle
                                code
                                assets {
                                    ... on APIAsset {
                                        id
                                        index
                                    }
                                }
                            }
                            classification
                            minutes
                            steps
                            where
                        }
                        ... on DeletedRecord {
                            __typename
                            id
                        }
                    }
                }
            }
        `,
        { shareLink, changesSince, minimumInterval, maximumInterval },
        { returns: "regime.tasks" }
    )
)
