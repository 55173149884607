import { forwardRef } from "react"
import { useDialog } from "lib/@hooks/useDialog"
import { SignatureDialog } from "lib/@dialogs/signature-dialog"
import { useUserMetadata } from "lib/store/user"
import { ListItemBox } from "lib/@components/ListItemBox"
import { Box, Button, IconButton } from "@mui/material"
import { BlockClicks } from "lib/@components/block-clicks"
import Iconify from "minimals-template/components/Iconify"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { useCurrentUser } from "lib/store/use-current-user"
import { scopeUrl } from "lib/scope-url"

export const Signature = forwardRef(function Signature(props, ref) {
    const [userSignature, setUserSignature] = useBoundValue("userSignature", "")
    const [userSignedName, setName] = useBoundValue("userSignedName", "")
    const meta = useUserMetadata()
    const getSignature = useDialog(<SignatureDialog />)
    const { signature } = useCurrentUser()
    const { readOnly } = useBoundContext()
    return (
        <ListItemBox px={1.5} py={2} ref={ref} {...props}>
            {!userSignature && <Box flex={1} />}
            <Box
                sx={{
                    display: "flex",
                    py: 1,
                    flex: 1,
                    pointer: "default",
                    backgroundColor: userSignature ? "#fffa" : undefined,
                    borderRadius: 1,
                }}
            >
                <Box flex={1} />
                {!userSignature && (
                    <BlockClicks>
                        <Button variant="contained" color="primary" onClick={() => sign()}>
                            Sign
                        </Button>
                    </BlockClicks>
                )}
                {userSignature && (
                    <Box
                        component="img"
                        flex={1}
                        alt="signature"
                        onClick={() => sign(true)}
                        src={scopeUrl(userSignature)}
                        sx={{
                            objectFit: "contain",
                            cursor: "pointer",
                            maxHeight: props.height ?? 110,
                        }}
                    />
                )}
                <Box flex={1} />
            </Box>
            <Box flex={1} />
            {userSignature && !readOnly && (
                <IconButton onClick={() => setUserSignature("")}>
                    <Iconify icon="mdi:clear-circle-outline" />
                </IconButton>
            )}
        </ListItemBox>
    )

    async function sign(replace) {
        if (!userSignedName && meta?.displayName) {
            setName(meta.displayName)
        }
        if (signature && !replace) {
            setUserSignature(signature)
        } else {
            const newSignature = await getSignature()
            if (newSignature) {
                setUserSignature(newSignature)
            }
        }
    }
})
