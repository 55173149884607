import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useEvent } from "lib/@hooks/useEvent"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { raise } from "library/local-events"
import { RunMe } from "lib/@components/slot/index"
import { Frag } from "lib/@components/slot/frag"
import { Fragment } from "react"

export function useItems(type, items = []) {
    items = items.slice(0)
    const context = useBoundContext()
    useEvent(`RefreshSlot.${type}`, useRefresh(noChange))
    raise(`Slot.${type}`, {
        items,
        context,
        add(item) {
            if (item.type) {
                if (item.type === Fragment) {
                    items.push(<Frag {...item.props} __type={type} />)
                } else {
                    items.push(<item.type {...item.props} __type={type} />)
                }
            } else {
                items.push(item)
            }
        },
    })
    let toRun = items.filter(Boolean).sortBy((i) => i?.props?.priority ?? i?.priority ?? 100)
    let toExecute
    do {
        toExecute = toRun.find((i) => i[RunMe])
        if (toExecute) {
            const upToHere = toRun.indexOf(toExecute)
            let before = toRun.slice(0, upToHere)
            const after = toRun.slice(upToHere)
            const props = { items: before, type, context }
            const result = toExecute.fn(props)
            before = props.items
            toRun = [...before, ...after]
            const idx = toRun.indexOf(toExecute)
            if (idx !== -1) {
                if (result) {
                    toRun[idx] = result
                } else {
                    toRun.splice(idx, 1)
                }
            }
        }
    } while (toExecute)
    return toRun.filter(Boolean)
}
