import { Box, Card, CardContent, Stack } from "@mui/material"
import { BoundAutocomplete, BoundTextField } from "lib/@components/binding/bound-components"
import { useSkills } from "routes/@lib/skills"

export function AccountDetails() {
    const [skills] = useSkills()

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Card sx={{ minHeight: "350px" }}>
                <CardContent>
                    <Stack spacing={3}>
                        <Stack spacing={3} direction="row" alignItems="center">
                            <BoundTextField field="UserName" id="editAccountEmailAddress" disabled />
                        </Stack>
                        <BoundTextField
                            field="displayName"
                            label="Name"
                            id="editAccountName"
                            yup="string.trim.required"
                            transformOutOnBlur={(name) => name?.trim()}
                        />
                        <BoundTextField field="jobTitle" id="editAccountPosition" />
                        <BoundAutocomplete
                            data-cy="edit-account-skills"
                            default={[]}
                            multiple
                            field="skills"
                            filterSelectedOptions
                            options={Object.keys(skills).sortBy((c) => skills[c]?.Skilling)}
                            getOptionLabel={(o) => `${skills[o]?.Skilling} [${skills[o]?.SkillingCode}]`}
                        />
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    )
}
