import { pick } from "lib/pick"
import { createSortFunction } from "companion/routes/schedule/create-sort-function"
import { ensureArray } from "lib/ensure-array"
import { insertIntoHierarchy } from "companion/routes/schedule/insert-into-hierarchy"

// const frequency = tasks(
//     sort(
//         name((c) => {
//             const frequency = c.groupFrequency ?? c.frequency
//             return `${frequency.interval} ${frequency.period.pluralize(frequency.interval)}`
//         }, "frequency"),
//         (c) => findTaskIn(c)?.intervalInHours ?? 0
//     ),
//     duplicateTasks
// )

// const periods = {
//     Unknown: Infinity,
//     Hour: Infinity,
//     Day: 1,
//     Week: 7,
//     Month: 28,
//     Year: 28 * 12,
// }
//
// function multiplyHours(task) {
//     return periods[task.frequency.period] * task.frequency.interval
// }

// function duplicateTasks(tasks) {
//     const allFrequencies = tasks.groupBy(multiplyHours)
//
//     const output = [...tasks]
//     const entries = Object.entries(allFrequencies).reverse()
//     for (const [interval, list] of entries) {
//         const subElements = entries
//             .map(([childInterval, list]) => {
//                 if (+interval > +childInterval && +interval % +childInterval === 0) {
//                     return list
//                 }
//                 return null
//             })
//             .filter(Boolean)
//             .flat(Infinity)
//         output.push(
//             ...subElements.unique("id").map((element) => ({
//                 ...element,
//                 id: `${element.id}-${interval}`,
//                 groupFrequency: list[0].frequency,
//                 intervalInHours: list[0].intervalInHours,
//             }))
//         )
//     }
//
//     return output.unique("id")
// }
//
// function findTaskIn(c) {
//     for (const child of c.children ?? []) {
//         if (child?.steps) return child
//         const subItem = findTaskIn(child)
//         if (subItem) return subItem
//     }
//     return null
// }

const where = name(pick("_where"), "@where")
const classification = name(pick("classification"), "classification")
const skill = name(pick("skill.Skilling"), "skill")
export const treeModes = {
    skillInLocation: treeBy(skill, where),
    skillClassificationInLocation: treeBy(skill, classification, where),
    where: treeBy(where),
    skill: treeBy(skill, where),
    classification: treeBy(classification, where),
}

function treeBy(...props) {
    let id = 1
    return function makeTree(tasks) {
        const allTasks = Object.values(tasks)
        const sorted = allTasks.sort(createSortFunction(...props))

        id = 1
        const result = { id: 0, children: process(sorted, [...props, () => ({})]), value: "Home" }

        scan(result)
        return result
    }

    function scan(item, index = {}) {
        index[item.id] = item
        if (item.children) {
            item.$index = index
            item.children.forEach((c) => scan(c, index))
        }
        return index
    }

    function process(tasks, props) {
        const prop = props[0]
        const taskFn = prop._tasks ?? ((v) => v)
        const groups = taskFn(tasks).groupBy((c) => prop(c))
        const organizedGroups = Object.entries(groups)
            .map(([, list]) =>
                props.length > 1
                    ? {
                          id: id++,
                          children: Object.values(process(list, props.slice(1))).sortBy(
                              (c) => ensureArray(c.value).length
                          ),
                          value: prop(list[0]),
                          type: prop._type,
                          sortOrder: prop._sort,
                      }
                    : list
            )
            .flat(Infinity)
            .sortBy((c) => ensureArray(c.value).length)

        // Check if the value is an array before calling insertIntoHierarchy
        const isArrayValue = organizedGroups.some((group) => Array.isArray(group.value))

        if (isArrayValue) {
            // Apply the insertIntoHierarchy function to further organize the hierarchy
            return insertIntoHierarchy(organizedGroups)
        }

        return organizedGroups // Return original data structure if value is not an array
    }
}

function name(fn, name) {
    fn._type = name
    return fn
}

// function sort(fn, sortFn) {
//     fn._sort = sortFn
//     return fn
// }
//
// function tasks(fn, taskFn) {
//     fn._tasks = taskFn
//     return fn
// }
