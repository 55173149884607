import { Grid, Stack } from "@mui/material"
import { PageContainer } from "lib/@components/page-container"
import { HelpTag, HELPTAG_HOME } from "routes/help/helpTag"
import { HomePageTile } from "slot-definitions"
import { If } from "lib/@components/switch"
import { hasDemand } from "lib/authorization/has-demand"
import { ViewerOnly } from "minimals-template/components/guards/viewer-only"

export function HomePage() {
    return (
        <PageContainer title="Home">
            <If
                truthy={hasDemand("schedules")}
                then={
                    <>
                        <HelpTag tags={[HELPTAG_HOME]} />
                        {/*<Image*/}
                        {/*    visibleByDefault*/}
                        {/*    disabledEffect*/}
                        {/*    alt={"HomePageLogo"}*/}
                        {/*    src={"/logo/primary_logo_lockup.svg"}*/}
                        {/*    sx={{ height: 110, width: 275, mt: 2 }}*/}
                        {/*/>*/}
                        <Stack spacing={2}>
                            <Grid container spacing={2} sx={{ width: 1, my: 5 }}>
                                <HomePageTile.Slot />
                            </Grid>
                        </Stack>
                    </>
                }
                else={<ViewerOnly />}
            />
        </PageContainer>
    )
}
