const processed = Symbol("processed")

export function insertIntoHierarchy(nodes) {
    // Helper function to check if array a is a prefix of array b
    for (const node of nodes) {
        if (node.value.length > 1) {
            const candidates = nodes.filter((c) => c.value.length === node.value.length - 1)
            const parent = candidates.find((c) => isPrefix(c.value, node.value))
            if (parent) {
                node[processed] = true
                parent.children.push(node)
            }
        }
    }
    return nodes.filter((v) => !v[processed])

    function isPrefix(a, b) {
        return a.every((val, index) => val === b[index])
    }
}
