import { Box, Button, Card, CardContent, Stack, Typography } from "@mui/material"
import { DATE_FORMATS } from "lib/@constants/date-format"
import { useUserMetadata } from "lib/store/user"
import { kebabCase } from "lodash"
import { displayIntervalLookup } from "plugins/Paywall/paywall-constants"
import { formatDuration } from "plugins/Paywall/paywall-utils"
import { getStripeSubscription } from "plugins/stripe/controller/get-stripe-subscription"

export function AccountLicence() {
    const meta = useUserMetadata()
    const { stripeCustomer } = meta || {}

    const { data } = getStripeSubscription.useResults.status(stripeCustomer || "")
    if (!data) return null

    const { amount, expiry, period, status, portalUrl, modified, mode, cancelled } = data || {}
    const renewalNA = cancelled ?? mode === "payment"
    const displayPeriod = displayIntervalLookup[period]
    const expiryDiff = expiry - Date.now() / 1000
    const daysRemaining = expiryDiff < 0 ? "expired" : formatDuration(expiryDiff)
    const renewalDate = renewalNA ? "N/A" : new Date(expiry * 1000).format(DATE_FORMATS.medium)
    const purchaseDate = new Date(modified).format(DATE_FORMATS.medium)
    const amountPaid = (amount / 100).toLocaleString("en-gb", {
        style: "currency",
        currency: "GBP",
    })
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Card sx={{ mb: 3 }}>
                <CardContent>
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <Typography variant="h3">Individual Licence</Typography>
                        <Box sx={{ display: "flex", gap: 2, width: "100%", flexWrap: "wrap" }}>
                            <Licenceinfo title="Licence Type" info={displayPeriod} />
                            <Licenceinfo title="Time Remaining" info={daysRemaining} />
                            <Licenceinfo title="Renewal Date" info={renewalDate} />
                            <Licenceinfo title="Last Payment" info={purchaseDate} />
                            <Licenceinfo title="Payment Status" info={status} />
                            <Licenceinfo title="Last amount paid" info={amountPaid} />
                        </Box>
                    </Stack>
                    <Button variant="outlined" sx={{ width: "100%" }} onClick={() => window.open(portalUrl, "_blank")}>
                        Manage Licence
                    </Button>
                </CardContent>
            </Card>
        </Box>
    )
}

const Licenceinfo = ({ title, info }) => (
    <Box sx={{ width: "49%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="body1" color="textSecondary" sx={{ textTransform: "capitalize" }}>
            {title}
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "left", width: "150px", textTransform: "capitalize" }}>
            <strong data-cy={kebabCase(title)}>{info}</strong>
        </Typography>
    </Box>
)
