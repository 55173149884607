import { Box, Button, Stack, Typography } from "@mui/material"
import { MdArrowLeft } from "@react-icons/all-files/md/MdArrowLeft"
import { EmptyContentIllustration as DefaultErrorIllustration } from "minimals-template/assets"
import { ToolbarBox } from "./toolbarBox"
import { ListItemBox } from "./ListItemBox"
import { useNavigate } from "react-router"

export function DisplayError({
    errorHeader = "Oops!",
    errorMessage = "An error occurred",
    illustration: IllustrationComponent = DefaultErrorIllustration,
    width = 275,
    mt = 7,
}) {
    const navigate = useNavigate()
    return (
        <>
            <ToolbarBox>
                <ListItemBox mb={1}>
                    <Button onClick={handleBack} startIcon={<MdArrowLeft />} variant="contained">
                        Back
                    </Button>
                </ListItemBox>
            </ToolbarBox>

            <Stack alignItems="center" spacing={2} mt={mt}>
                <Box sx={{ width }}>
                    <IllustrationComponent />
                </Box>

                <Typography variant="h6" color="text.secondary">
                    {errorHeader}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {errorMessage}
                </Typography>
            </Stack>
        </>
    )
    function handleBack() {
        navigate(-1)
    }
}
