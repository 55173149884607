import noop from "lib/noop"
import { useEffect, useRef, useState } from "react"
import { Box, FormControlLabel, MenuItem, Pagination, Select, Typography } from "@mui/material"
import { NotOnMobile, OnMobile, useMobile } from "lib/@components/mobile"
import { ListItemBox } from "lib/@components/ListItemBox"
import { setFromEvent, setFromValueParam } from "lib/setFromEvent"
import { Repeat } from "lib/@components/repeat"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { createPortal } from "react-dom"
import { useReferenceState } from "lib/@hooks/use-reference"
import { defaultNumberOfRecords } from "routes/asset-register/default-number-of-records"

export function PagedRepeat({
    collection,
    page: initialPage = 1,
    onPageChange = noop,
    showBottom = true,
    showTop = false,
    showPaginator = true,
    sx,
    color = "primary",
    variant,
    list = collection,
    pageSize: initialPageSize = 10,
    pageSizeName = "general",
    Component = Box,
    ...props
}) {
    const isMobile = useMobile()

    const [pageSize, setPageSize] = useReferenceState(`${pageSizeName}-general-page-size`, initialPageSize)
    const { paginationPortal } = useBoundContext()
    // eslint-disable-next-line prefer-const
    let [page, setPage] = useState(initialPage)
    const numberOfPages = Math.ceil(list.length / pageSize)
    const numberOnLastPage = list.length % pageSize
    page = Math.max(1, Math.min(page, numberOfPages))
    const lastNumberOfPages = useRef(numberOfPages)

    const paginator = showPaginator && list.length > defaultNumberOfRecords[0] && (
        <>
            <OnMobile>
                <ListItemBox>
                    <Box flex={1} />
                    <Pagination
                        size={isMobile ? "large" : "small"}
                        variant={variant}
                        boundaryCount={isMobile ? 1 : undefined}
                        siblingCount={isMobile ? 0 : undefined}
                        onChange={setFromValueParam((v) => {
                            onPageChange(v)
                            setPage(v)
                        })}
                        color={color}
                        count={numberOfPages}
                        page={page}
                    />
                </ListItemBox>
                <ListItemBox mb={1} spacing={1}>
                    <Box flex={1} />
                    <FormControlLabel
                        label={<Typography variant="body2">Rows per page: </Typography>}
                        labelPlacement="start"
                        control={
                            <Select
                                sx={{ mr: 2, ml: 2, fontSize: isMobile ? 16 : 13 }}
                                variant="standard"
                                labelId="rows-per-page-label"
                                value={pageSize}
                                onChange={setFromEvent(setPageSize)}
                            >
                                {defaultNumberOfRecords.map((rowCountValue) => (
                                    <MenuItem key={rowCountValue} value={rowCountValue}>
                                        {rowCountValue}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </ListItemBox>
            </OnMobile>
            <NotOnMobile>
                <ListItemBox mb={1} spacing={1}>
                    <Box flex={1} />
                    <FormControlLabel
                        data-cy="pagination-rows-per-page"
                        label={<Typography variant="body2">{isMobile ? "Rows" : "Rows per page: "}</Typography>}
                        labelPlacement="start"
                        control={
                            <Select
                                sx={{ mr: 2, ml: 2, fontSize: isMobile ? 16 : 13 }}
                                variant="standard"
                                labelId="rows-per-page-label"
                                value={pageSize}
                                onChange={setFromEvent(setPageSize)}
                            >
                                {defaultNumberOfRecords.map((rowCountValue) => (
                                    <MenuItem key={rowCountValue} value={rowCountValue}>
                                        {rowCountValue}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    />
                    <Pagination
                        size={isMobile ? "large" : "small"}
                        variant={variant}
                        boundaryCount={isMobile ? 1 : undefined}
                        siblingCount={isMobile ? 0 : undefined}
                        onChange={setFromValueParam((v) => {
                            onPageChange(v)
                            setPage(v)
                        })}
                        color={color}
                        count={numberOfPages}
                        page={page}
                        data-cy="paged-repeat-pagination"
                    />
                </ListItemBox>
            </NotOnMobile>
        </>
    )

    useEffect(() => {
        if (lastNumberOfPages.current !== numberOfPages) {
            setPage(1)
        } else if (page !== initialPage) onPageChange(page)
        lastNumberOfPages.current = numberOfPages
        // eslint-disable-next-line
    }, [page, onPageChange, initialPage, numberOfPages])
    return (
        <Component sx={sx}>
            <NotOnMobile>
                {showTop && (paginationPortal ? createPortal(paginator, paginationPortal) : paginator)}
            </NotOnMobile>
            <OnMobile>{paginator}</OnMobile>
            <Repeat {...props} list={list.slice((page - 1) * pageSize, page * pageSize)} />
            <NotOnMobile>
                {showBottom &&
                    (paginationPortal
                        ? createPortal(paginator, paginationPortal)
                        : (!showTop || page !== numberOfPages || numberOnLastPage > 4 || numberOnLastPage === 0) &&
                          paginator)}
            </NotOnMobile>
        </Component>
    )
}
