import { PageContainer } from "lib/@components/page-container"
import { useCallback, useEffect, useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import { useRefresh } from "lib/@hooks/useRefresh"
import { Box, Button, Stack, Typography } from "@mui/material"
import { navigate } from "lib/routes/navigate"
import { alert } from "lib/@dialogs/alert"
import { useSearchParam } from "lib/@hooks/use-search-param"
import Logo from "minimals-template/components/Logo"
import { companionAppRoute } from "companion/routes/companion-app-route"
import { Word } from "companion/routes/whatwords/word"
import { ListItemBox } from "lib/@components/ListItemBox"
import { ReactComponent as FacilitiesLogo } from "routes/@assets/Icon - Facilities-iQ.svg"

companionAppRoute.register("", <ThreeWords index />)

function ThreeWords() {
    const [state] = useState({})
    const [phrase] = useSearchParam("phrase")
    const go = useCallback(navigateToSchedule, [state])

    useEffect(() => {
        const words = phrase?.split(" ") ?? []
        if (words.length > 2) {
            state.word1 = words[0]
            state.word2 = words[1]
            state.word3 = words[2]
            go()
        }
    }, [phrase, go, state])
    const refresh = useRefresh()
    return (
        <Bound target={state} refresh={refresh}>
            <PageContainer title="Smart Words">
                <Stack spacing={2} sx={{ mt: 2, px: 1, overflow: "auto" }}>
                    <ListItemBox>
                        <Logo sx={{ height: 40, mt: 1 }} />
                        <Box flex={1} />

                        <FacilitiesLogo height={48} />
                    </ListItemBox>
                    <Box py={2}>
                        <Typography color="text.secondary" variant="body1" gutterBottom component="div">
                            Enter the Smart Words you&apos;ve been provided
                        </Typography>
                    </Box>
                    <Word field="word1" label="First word" textFieldProps={{ autoFocus: true }} />
                    <Word field="word2" label="Second word" />
                    <Word field="word3" label="Third word" sideEffects />
                    <Box height={40} />
                    <Button onClick={navigateToSchedule} size="large" variant="contained">
                        Go
                    </Button>
                </Stack>
            </PageContainer>
        </Bound>
    )

    function navigateToSchedule() {
        if (!state.word1 || !state.word2 || !state.word3) {
            alert("You must enter all three Smart Words", "Entry error").catch(console.error)
        } else {
            navigate(`/sfg20/schedule/${state.word1}/${state.word2}/${state.word3}?keepActive=true`)
        }
    }
}
