import { useEffect, useMemo, useState } from "react"
import { PagedRepeat } from "lib/@components/paged-repeat"
import { Avatar, Box, Button, Card, InputAdornment, Link, Stack, Typography } from "@mui/material"

import AuthTemplate from "minimals-template/pages/auth/AuthTemplate"
import { If } from "lib/@components/switch"
import { DebouncedTextField } from "lib/@components/debounced-text-field"
import { MdSearch } from "@react-icons/all-files/md/MdSearch"
import { setFromEvent } from "lib/setFromEvent"
import Iconify from "minimals-template/components/Iconify"
import noop from "lib/noop"

import LoadingScreen from "minimals-template/components/LoadingScreen"

import { RouterLink } from "lib/routes/router-link"
import useAuth from "minimals-template/components/@hooks/useAuth"
import { useLocation } from "react-router"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import logo from "routes/@assets/PRIMARY LOGO _ LOCKUP _ REVERSED@3x.png"

const PAGE_SIZE = 4

function ClientCard({ client, onSelect = noop }) {
    const { name, city, photoURL } = client

    return (
        <Card sx={{ display: "flex", alignItems: "center", p: 3, mb: 3 }}>
            <Avatar alt={name} src={photoURL} sx={{ width: 48, height: 48 }} />

            <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
                <Typography variant="subtitle2" noWrap>
                    {name}
                </Typography>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Iconify
                        icon={"eva:pin-fill"}
                        sx={{ width: 16, height: 16, mr: 0.5, flexShrink: 0, color: "text.secondary" }}
                    />
                    <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                        {city}
                    </Typography>
                </Box>
            </Box>

            <Button
                size="small"
                onClick={handleClick}
                variant={"outlined"}
                color={"primary"}
                startIcon={<Iconify icon={"eva:checkmark-fill"} />}
                sx={{ flexShrink: 0 }}
                className="client-select-button"
            >
                Select
            </Button>
        </Card>
    )

    function handleClick() {
        onSelect(client)
    }
}

export function ClientSelect({ children }) {
    const [filteredClients, setFilteredClients] = useState([])
    const { pathname } = useLocation()
    const { clients: allClients, setSelectedClient, selectedClient, selectedClientInitialised } = useBoundContext()
    const { logout } = useAuth()

    const clients = useMemo(() => {
        if (!allClients) return null
        if (allClients.length === 1) return allClients
        return allClients?.filter((client) => client.license !== null)
    }, [allClients])

    const [showLoadingScreen, setShowLoadingScreen] = useState(true)

    useEffect(() => {
        if (!clients) return
        setFilteredClients(clients)
    }, [clients])

    useEffect(() => {
        const shouldShowLoading = !clients || !selectedClientInitialised
        setShowLoadingScreen(shouldShowLoading)
    }, [clients, selectedClientInitialised])

    if (showLoadingScreen) return <LoadingScreen />
    if (pathname.includes("logout")) return <>{children}</>
    if (clients?.length === 1 && !selectedClient) {
        setSelectedClient(clients[0]?.id)
        return false
    }

    if (selectedClient) return <>{children}</>

    return (
        <AuthTemplate
            pageTitle={"Client Selector"}
            pageImage={logo}
            headerComponent={
                <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                    <Link variant="subtitle2" component={RouterLink} onClick={logout}>
                        Logout
                    </Link>
                </Typography>
            }
        >
            <Stack>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Select a Company
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    You hold accounts with several organisations. Please choose the one you wish to access at this time.
                </Typography>
                <Box sx={{ mt: 1, mb: 3 }}>
                    <If truthy={clients.length > PAGE_SIZE}>
                        <Box mt={1} mb={1}>
                            <DebouncedTextField
                                fullWidth
                                placeholder="Filter..."
                                autoFocus
                                onChange={setFromEvent(handleSearch)}
                                InputProps={{
                                    startAdornment: (
                                        <Box ml={1} color="primary.main">
                                            <InputAdornment position="start">
                                                <MdSearch />
                                            </InputAdornment>
                                        </Box>
                                    ),
                                }}
                            />
                        </Box>
                    </If>
                </Box>
                <Box sx={{ height: PAGE_SIZE * 100 }}>
                    <PagedRepeat
                        pageSize={PAGE_SIZE}
                        list={filteredClients}
                        onClick={handleSelect}
                        pass="client"
                        item={<ClientCard onSelect={handleSelect} />}
                    />
                </Box>
            </Stack>
        </AuthTemplate>
    )

    function handleSearch(value) {
        setFilteredClients(clients.filter((client) => client.name.toLowerCase().includes(value.toLowerCase())))
    }

    function handleSelect(client) {
        setSelectedClient(client?.id)
    }
}
