import { useParams } from "react-router-dom"
import { scheduleFromWords } from "companion/routes/schedule/controller/schedule-from-words"
import { FETCH } from "lib/graphql/api"
import { useScheduleSettings } from "routes/schedule/lib/useScheduleSettings"
import { Bound } from "lib/@components/binding/Bound"
import { ScheduleAndTaskRender } from "companion/routes/schedule/schedule-and-task-render"
import { Alert, Button, Stack } from "@mui/material"
import Logo from "minimals-template/components/Logo"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { navigate } from "lib/routes/navigate"
import { alert } from "lib/@dialogs/alert"

export function ScheduleLookup() {
    const { word1, word2, word3 } = useParams()
    const {
        schedule = "na",
        taskId,
        noLicenseAvailable,
        reason,
        introductions,
    } = scheduleFromWords.useResults.offlineCache(`${word1} ${word2} ${word3}`, FETCH) ?? {}

    const [, set] = useScheduleSettings()
    set({ showDetail: false })

    return taskId ? (
        schedule ? (
            <Bound introductions={introductions} schedule={schedule} taskId={taskId}>
                <ScheduleAndTaskRender schedule={schedule} taskId={taskId} />
            </Bound>
        ) : !noLicenseAvailable ? (
            <Stack spacing={2} sx={{ mt: 2, px: 1 }}>
                <Logo sx={{ height: 64 }} />
                <Alert severity="error">The schedule and task were not found using those words.</Alert>
                <Button onClick={go}>Try Again</Button>
            </Stack>
        ) : (
            <Stack spacing={2} sx={{ mt: 2, px: 1 }}>
                <Logo sx={{ height: 64 }} />
                <Alert severity="error">Cannot access schedule. {reason}.</Alert>
                <Button onClick={go}>Try Again</Button>
            </Stack>
        )
    ) : (
        <LoadingScreen timeout={10000} afterTimeout={returnToPrevious} />
    )

    async function returnToPrevious() {
        navigate("/sfg20")
        await alert("Unable to retrieve the schedule", "Error")
    }

    function go() {
        navigate("/sfg20")
    }
}
