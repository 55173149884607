import { Bound } from "lib/@components/binding/Bound"
import { TaskRowAfter, TaskRowBefore } from "slot-definitions"
import { memo, useState } from "react"
import { Stack } from "@mui/material"
import { PropertyBox } from "lib/@components/property-box"
import { DragHandle } from "lib/dragHandle"
import { SortableItem } from "lib/@components/SortableItem"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export const TaskRow = memo(function TaskRow({ item, children }) {
    const { editMode } = useBoundContext()
    const [props, setProps] = useState({})
    return (
        <SortableItem
            className="task-row"
            data-cy={`task-row-${item.id}`}
            setDragProps={setProps}
            disabled={!editMode}
            id={item.$sort}
        >
            <Bound target={item}>
                <TaskRowBefore.Slot />
                <Stack direction="row" alignItems="center">
                    {!!editMode && <DragHandle {...props} />}
                    <PropertyBox>{children}</PropertyBox>
                </Stack>
                <TaskRowAfter.Slot />
            </Bound>
        </SortableItem>
    )
})
