import { Alert, Box, Card, Divider, Grid, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import Iconify from "minimals-template/components/Iconify"
import { User } from "lib/user/user"
import { AdoptClient, FlushGraphQLCache } from "event-definitions"
import { DATE_FORMATS } from "lib/@constants/date-format"

export function LinkCard({ item }) {
    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ width: 1, cursor: "pointer" }} role="button" onClick={go}>
                <ListItemBox spacing={2} sx={{ p: 2 }}>
                    <Iconify icon="mdi:link-variant" sx={{ fontSize: 24, color: "primary.main" }} />
                    <Typography component="div" sx={{ flex: 1 }} variant="body1">
                        {item.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        Until {new Date(item.endDate).format(DATE_FORMATS.medium)}
                    </Typography>
                </ListItemBox>
                <Typography component="div" sx={{ color: "primary.main", px: 2, py: 1 }} variant="caption">
                    {item.url}
                </Typography>
                <Divider />
                <Box p={2}>
                    <User id={item.creator} />
                </Box>
                {item.outOfDate && (
                    <Box p={2} pt={0}>
                        <Alert severity="warning">There has been an update to this share link</Alert>
                    </Box>
                )}
            </Card>
        </Grid>
    )

    function go() {
        FlushGraphQLCache.raise()
        AdoptClient.raise(undefined)
        window.location.href = item.url
    }
}
