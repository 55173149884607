export function combineSearchParams(newUrl) {
    const currentUrlParams = new URLSearchParams(window.location.search)
    const newUrlObject = new URL(newUrl)

    if (newUrlObject.pathname.includes("/auth/request")) {
        // Improved magic token security
        // if this is an auth request, auto populate "autoLogin" into searchParams if it's set in localstorage
        let autoLogin = localStorage.getItem("autoLogin")
        if (autoLogin) {
            console.warn(`autoLogin config used from local storage for path ${newUrlObject.pathname}`)
            newUrlObject.searchParams.set("autoLogin", autoLogin)
        }
    }

    // Combine new URL's search params with current URL's params
    currentUrlParams.forEach((value, key) => {
        // Append or override new URL's params with current ones
        newUrlObject.searchParams.set(key, value)
    })
    return newUrlObject.toString()
}
