import { useState } from "react"
import { useRefresh } from "lib/@hooks/useRefresh"
import { Bound } from "lib/@components/binding/Bound"
import { DialogBox } from "lib/@components/dialogBox"
import { Box, Stack, Typography } from "@mui/material"
import { Signature } from "routes/conformity/plugins/sign-off/signature"

export function CompleteDialog({ title }) {
    const [state] = useState({})
    const refresh = useRefresh()

    return (
        <Bound target={state} refresh={refresh} onChange={refresh}>
            <DialogBox sx={{ height: "100%" }} title="Complete Task" value={state} okDisabled={!state.userSignature}>
                <Box pb={4} />
                <Stack spacing={1}>{title}</Stack>
                <Typography gutterBottom component="div" sx={{ mt: 2 }}>
                    I confirm that I am qualified and trained to undertake the task listed above.
                </Typography>
                <Typography gutterBottom component="div" sx={{ mt: 2 }}>
                    I confirm that I have completed the task in accordance with the complete schedule and all
                    introductory procedures, including the overarching introduction.
                </Typography>
                <Signature width={window.innerWidth - 64} />
                <Box flex={1} />
            </DialogBox>
        </Bound>
    )
}
