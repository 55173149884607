import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useEffect, useState } from "react"
import { ListItemBox } from "lib/@components/ListItemBox"
import { Box, Checkbox, ListItem, ListItemText } from "@mui/material"

export function Step({ item, last }) {
    const { allSelected, setAllSelected, completed } = useBoundContext()
    const [isSelected, setIsSelected] = useState(allSelected[item] ?? false)

    useEffect(() => {
        setAllSelected((a) => ({ ...a, [item]: isSelected }))
    }, [isSelected, setAllSelected, item])

    return (
        <ListItem divider={!last} component="div">
            <ListItemBox spacing={1} pr={4} onClick={toggle}>
                <Box>
                    <Checkbox
                        disabled={!!completed}
                        className={`checkbox ${isSelected ? "checked" : "not-checked"}`}
                        checked={!!completed || isSelected}
                    />
                </Box>
                <Box flex={1}>
                    <ListItemText primary={item} />
                </Box>
            </ListItemBox>
        </ListItem>
    )

    function toggle() {
        setIsSelected((c) => !c)
    }
}
