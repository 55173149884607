import { RefreshSchedules } from "event-definitions"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { getTreeIndex } from "routes/schedule/lib/getTreeIndex"
import { useRef } from "react"

export function useTreeItemIndex(shouldRefresh = true) {
    const refresh = useRefresh(noChange)
    const result = useRef()
    result.current = getTreeIndex()
    RefreshSchedules.useEvent(() => {
        if (shouldRefresh || !result.current) {
            refresh()
        }
    }, [shouldRefresh])
    return result.current
}
