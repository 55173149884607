import { useState } from "react"
import { useDarkMode } from "lib/use-dark-mode"
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { Bound } from "lib/@components/binding/Bound"
import { useIntroductions } from "lib/use-introductions"
import { uniqueId } from "library/database/split-id"

export function Introduction({ item: id }) {
    const [open, setOpen] = useState(false)
    const isDark = useDarkMode()
    const introductions = useIntroductions()
    const item = introductions?.[`${uniqueId(id)}:sfg20/schedule`]
    console.log(id, introductions, item)
    return (
        !!item && (
            <Accordion TransitionProps={{ unmountOnExit: true }} open={open} onChange={() => setOpen((p) => !p)}>
                <AccordionSummary
                    expandIcon={
                        <Iconify
                            icon={open ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
                            sx={{ width: 16, height: 16, ml: 1 }}
                        />
                    }
                >
                    <Typography variant={"subtitle1"}>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Bound target={item}>
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: item?.intro?.content ?? "" }} />
                        {!!item.intro.notes && (
                            <>
                                <Typography variant="overline">Notes</Typography>
                                <Box sx={{ color: isDark ? "#aaa" : "#333" }}>
                                    {/* eslint-disable-next-line react/no-danger */}
                                    <div dangerouslySetInnerHTML={{ __html: item?.intro?.note ?? "" }} />
                                </Box>
                            </>
                        )}
                    </Bound>
                </AccordionDetails>
            </Accordion>
        )
    )
}
