export function getAllTasks(item, output = []) {
    if (item?.steps && !item?.groupFrequency) {
        output.push(item)
    }
    for (const child of item.children ?? []) {
        getAllTasks(child, output)
    }
    return output
}

export function getAllTasksInTree(item, output = []) {
    if (item?.steps) {
        output.push(item)
    }
    for (const child of item.children ?? []) {
        getAllTasksInTree(child, output)
    }
    return output
}
