const Sugar = require("sugar")
require("sugar-inflections")
const { MapPlus } = require("library/map-plus")

// Define the keyBy method
Sugar.Array.defineInstance({
    keyBy(array, key) {
        const original = key
        key = typeof key === "string" ? (v) => v[original] : key
        return array.reduce((result, item) => {
            result[key(item)] = item
            return result
        }, {})
    },
    keyByMap(array, key) {
        const original = key
        key = typeof key === "string" ? (v) => v[original] : key
        return array.reduce((result, item) => {
            result.set(key(item), item)
            return result
        }, new Map())
    },
    groupByMap(array, key) {
        const original = key
        key = typeof key === "string" ? (v) => v[original] : key
        return array.reduce(
            (result, item) => {
                result.get(key(item)).push(item)
                return result
            },
            new MapPlus(() => [])
        )
    },
})

// Now use Sugar.js to extend all arrays with the keyBy method
Sugar.extend()
