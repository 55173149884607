import noop from "lib/noop"
import { useItems } from "lib/@components/slot/use-items"
import { ensureArray } from "lib/ensure-array"
import { getSlotContentElementsWithWrapper } from "lib/@components/slot/get-slot-content-elements-with-wrapper"
import React from "react"

/**
 * Creates a slot with a known `type` that "plugs" may be added into.
 * The plugs may be individually wrapped with a `<Container/>` and all other properties are
 * passed to the plug instance.
 * @type {React.FunctionComponent<React.PropsWithoutRef<{type: String, Container: React.ReactElement}>>}
 */
export function Slot({ type, predicate = () => true, onEmpty = noop, children = [], wrapper, Container, ...props }) {
    const Type = Container?.type

    const containerProps = Container?.props
    const items = useItems(type, ensureArray(children))?.filter(predicate)
    if (!items.length) {
        setTimeout(() => onEmpty(true))
    } else {
        setTimeout(() => onEmpty(false))
    }
    return wrapper ? (
        <wrapper.type {...wrapper.props}>
            {getSlotContentElementsWithWrapper(items, props, containerProps, Type)}
        </wrapper.type>
    ) : (
        getSlotContentElementsWithWrapper(items, props, containerProps, Type)
    )
}
