import { useCallback, useEffect, useMemo, useRef } from "react"
import { debounce } from "lib/debounce"
import { normalizedStringify } from "library/normalized-stringify"
import { ToBeSaved } from "lib/@components/save-indicator/save-indicator"

export function useSave({ save: suppliedSaveFn, target, id, wait = 1000 }, deps = []) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const save = useCallback(suppliedSaveFn, [...deps, target])
    const currentTarget = useRef()
    currentTarget.current = target

    useEffect(() => {
        window.addEventListener("beforeunload", saveCurrent)
        return () => {
            window.removeEventListener("beforeunload", saveCurrent)
        }

        function saveCurrent() {
            save(currentTarget.current)
        }
    }, [save])

    const isDirty = useRef(false)
    const last = useRef(normalizedStringify(target))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const doSave = useCallback(
        debounce(
            () => {
                isDirty.current = false

                const current = normalizedStringify(currentTarget.current)
                if (current !== last.current) {
                    last.current = current
                    return save(currentTarget.current).then(() => {
                        if (id) ToBeSaved(id).raise(false)
                    }, console.error)
                }
                if (id) ToBeSaved(id).raise(false)

                return undefined
            },
            wait,
            { maxWait: 30000 }
        ),
        [save]
    )
    useEffect(
        () => () => {
            if (isDirty.current) {
                save(currentTarget.current)
            }
        },

        [save]
    )
    return useMemo(() => {
        const result = () => {
            isDirty.current = true
            if (id) ToBeSaved(id).raise(true)

            doSave()
        }
        result.flush = () => {
            isDirty.current = true
            doSave()
            doSave.flush()
        }
        return result
    }, [doSave, id])
}
