import { useSearchParam } from "lib/@hooks/use-search-param"
import { useDebouncedValue } from "lib/@hooks/useDebouncedValue"
import { Box, IconButton, InputAdornment, TextField } from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import { MdClear } from "@react-icons/all-files/md/MdClear"

export function SearchBoxUsingSearchParam({
    autoFocus = true,
    placeholder = "Search by schedule title or code...",
    sx,
}) {
    const [labelSearch, setLabelSearch] = useSearchParam("search", "")
    const [search, setSearch] = useDebouncedValue(labelSearch, 500, setLabelSearch)
    return (
        <Box py={2} m="auto" sx={sx}>
            <TextField
                className="search-param-box"
                data-cy="search-param-box"
                fullWidth
                value={search}
                autoFocus={autoFocus}
                onChange={setFromEvent(setSearch)}
                variant="standard"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton size="small" onClick={() => setSearch("")}>
                                <MdClear />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                placeholder={placeholder}
            />
        </Box>
    )
}
