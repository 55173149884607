import { Container, Stack, Typography } from "@mui/material"
import { Links } from "routes/shared-with-me/links"
import Image from "minimals-template/components/Image"

export function ViewerOnly() {
    return (
        <Container sx={{ width: 1, flex: 1, pointerEvents: "all" }}>
            <Stack spacing={2}>
                <Image
                    visibleByDefault
                    disabledEffect
                    alt={"HomePageLogo"}
                    src={"/logo/primary_logo_lockup.svg"}
                    sx={{ height: 110, width: 275, mt: 2 }}
                />
                <Typography variant="body1">
                    Welcome to SFG20. Your licence allows you to access any link shared with you. The links will appear
                    below or you can visit them directly.
                </Typography>
                <Links />
            </Stack>
        </Container>
    )
}
