import { Bound } from "lib/@components/binding/Bound"
import { Sortable } from "lib/@components/Sortable"
import { Box, List } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import { TaskOrHeader } from "routes/schedule/tasks/taskOrHeader"
import { ListItemBox } from "lib/@components/ListItemBox"
import { AddTaskButton } from "routes/schedule/edit/add-task"
import { TaskListRefresh } from "event-definitions"
import { arrayMoveInPlace } from "lib/ArrayMoveInPlace"
import { indexTasksInSchedule } from "routes/schedule/lib/index-tasks-in-schedule"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function TaskListDisplay({ tasks, schedule }) {
    const { onChange, editMode } = useBoundContext()
    schedule.tasks ??= []
    return (
        (!!tasks?.length || editMode) && (
            <>
                <Sortable onDragEnd={orderItems} items={tasks} id="$sort">
                    <List dense>
                        <Repeat list={tasks} item={<TaskOrHeader />} keyFn={(v) => v.id} />
                    </List>
                </Sortable>
                <Bound schedule={schedule}>
                    <ListItemBox>
                        <AddTaskButton />
                        <Box flex={1} />
                    </ListItemBox>
                </Bound>
            </>
        )
    )

    function orderItems({ active, over }) {
        if (!over) return false
        const draggedItem = schedule.tasks.findIndex((t) => active.id === `sort_${t.$sort}`)
        const overItem = schedule.tasks.findIndex((t) => over.id === `sort_${t.$sort}`)

        if (overItem < 0 || draggedItem < 0) {
            console.log("No match")
            return false
        }
        arrayMoveInPlace(schedule.tasks, draggedItem, overItem)
        indexTasksInSchedule(schedule)
        TaskListRefresh.raise()
        onChange()
        return false
    }
}
