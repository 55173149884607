import { useParams } from "react-router-dom"
import { payloadFromWords } from "companion/routes/schedule/controller/payload-from-words"
import { FETCH } from "lib/graphql/api"
import { useEffect } from "react"
import { navigate } from "lib/routes/navigate"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { alert } from "lib/@dialogs/alert"

export function ChoosePath() {
    const { word1, word2, word3 } = useParams()
    const { accessToken, shareLinkId, loading } = payloadFromWords.useResults.offlineCache(
        `${word1} ${word2} ${word3}`,
        FETCH
    ) ?? {
        loading: true,
    }

    useEffect(() => {
        if (!loading) {
            if (!accessToken && !shareLinkId) {
                navigate(`/sfg20/schedule/${word1}/${word2}/${word3}/details?keepActive=true`)
            } else {
                navigate(`/sfg20/tasks/${word1}/${word2}/${word3}`)
            }
        }
    }, [accessToken, loading, word1, word2, word3, shareLinkId])
    return <LoadingScreen timeout={10000} afterTimeout={returnToPrevious} />

    async function returnToPrevious() {
        navigate("/sfg20")
        await alert("Unable to retrieve the schedule", "Error")
    }
}
