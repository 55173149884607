import { Box, Stack } from "@mui/material"
import { Bound } from "lib/@components/binding/Bound"
import { useSave } from "lib/@hooks/use-save"
import { useUnmounted } from "lib/lifecycle"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { useRef } from "react"
import { AccountDetails } from "./account-details"
import { AccountPhoto } from "./account-photo"
import { updateUserProfileMetadata } from "./controller/update-user-profile-metadata"
import { useYupContext } from "lib/@components/binding/use-yup-wrapper"
import { getCurrentUser } from "controller/getCurrentUser"
import { PageBreadcrumbContainer } from "lib/page-breadcrumb-container"
import { AccountLicence } from "./account-licence"

export function Account() {
    const user = getCurrentUser.useResultsOnce() ?? {}
    const errors = useRef({})
    const [yupContext, validate] = useYupContext()
    const change = useSave({ save, target: user })
    useUnmounted(unmount)

    return (
        <Bound target={user} user={user} onChange={change} errors={errors.current} yupContext={yupContext}>
            <PageBreadcrumbContainer sx={{ display: "flex", height: 1, flex: 1 }} title={"Profile"}>
                <Box sx={{ pt: 3, flex: 1, overflowX: "hidden", overflowY: "scroll" }}>
                    <Stack spacing={3}>
                        <Stack
                            sx={{ height: 1, flex: 1, width: 1, marginTop: 2 }}
                            spacing={3}
                            direction={{ xs: "column", sm: "row" }}
                        >
                            {user.id && (
                                <>
                                    <AccountPhoto />
                                    <AccountDetails />
                                </>
                            )}
                        </Stack>
                        {user.id && <AccountLicence />}
                    </Stack>
                </Box>
            </PageBreadcrumbContainer>
        </Bound>
    )

    async function unmount() {
        if (!validate()) {
            errorSnackbar("Failed to save account")
        }
    }

    async function save(user) {
        try {
            if (user.id && validate()) {
                await updateUserProfileMetadata(user)
            }
        } catch (e) {
            errorSnackbar("Failed to save account")
        }
    }
}
