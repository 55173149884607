import { useEffect, useState } from "react"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { getAllTasks } from "companion/routes/tasks/get-all-tasks"
import { Box, List } from "@mui/material"
import { SearchBoxUsingSearchParam } from "lib/@components/searchBoxUsingSearchParam"
import { TreeListPage } from "slot-definitions"
import { Repeat } from "lib/@components/repeat"
import { TreeItem } from "companion/routes/tasks/tree-item"
import { NoResults } from "lib/@components/no-results/no-results"
import { Bound } from "lib/@components/binding/Bound"

export function Searcher() {
    const [results, setResults] = useState(null)
    const [search] = useSearchParam("search")

    let { target, setParentOverride, realParent } = useBoundContext()

    useEffect(() => {
        const realTarget = target.steps ? realParent : target
        if (search.trim()) {
            const lcSearch = search.toLowerCase()
            const tasks = getAllTasks(realTarget)
            const filteredTasks = tasks
                .filter(
                    (t) => t.title.toLowerCase().includes(lcSearch) || t.schedule.title.toLowerCase().includes(lcSearch)
                )
                .sortBy("title")
            setResults(filteredTasks)
            setParentOverride({ children: filteredTasks })
        } else {
            setParentOverride(null)
            setResults(null)
        }
    }, [search, target, setParentOverride, realParent])

    return (
        <Box px={1}>
            <SearchBoxUsingSearchParam autoFocus={false} placeholder="Find..." />

            {results && !target.steps && (
                <Bound parent={{ children: results }}>
                    <TreeListPage.Plug replace deps={[results]}>
                        <List>
                            <Repeat list={results} item={<TreeItem />} bind fallback={<NoResults />} />
                        </List>
                    </TreeListPage.Plug>
                </Bound>
            )}
        </Box>
    )
}
