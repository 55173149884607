import { setContext } from "@apollo/client/link/context"
import { getCurrentClientId } from "minimals-template/components/contexts/NognitoContext"
import { instanceId } from "lib/graphql/instance-id"
import { currentSession } from "minimals-template/components/contexts/passport"

export const authLink = setContext(async () => {
    try {
        const params = Object.fromEntries(new URLSearchParams(window.location.search).entries())
        if (params.alttoken) {
            return {
                headers: Object.fromEntries(
                    Object.entries({
                        authorization: `Bearer ${params.alttoken}`,
                        source: params.share,
                        clientid: getCurrentClientId(),
                        href: window.location.href,
                        instance: instanceId,
                    }).filter(([, v]) => !!v)
                ),
            }
        }

        const jwtToken = currentSession()

        return {
            headers: Object.fromEntries(
                Object.entries({
                    authorization: jwtToken !== "undefined" && jwtToken ? `Bearer ${jwtToken}` : undefined,
                    source: params.share,
                    clientid: getCurrentClientId(),
                    href: window.location.href,
                    instance: instanceId,
                }).filter(([, v]) => !!v)
            ),
        }
    } catch (e) {
        console.log(e)
        return { headers: { authorization: "No Token" } }
    }
})
