import { useTheme } from "@mui/material/styles"
import { Box } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { prevent } from "lib/prevent"
import noop from "lib/noop"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function DragHandle(props) {
    const theme = useTheme()
    const { hideDrag } = useBoundContext()
    return (
        !hideDrag && (
            <Box
                onClick={prevent(noop)}
                sx={{
                    lineHeight: 0,
                    ml: 0.5,
                    "&:focus": {
                        borderRadius: 0.25,
                        border: "none",
                        outline: "2px solid black",
                        outlineColor: theme.palette.primary.main,
                    },
                }}
                tabIndex={0}
                {...props}
                color="divider"
            >
                <Iconify icon="ic:outline-drag-indicator" />
            </Box>
        )
    )
}
