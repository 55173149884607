import { Fragment, useState } from "react"
import { useRefresh } from "lib/@hooks/useRefresh"
import { Bound } from "lib/@components/binding/Bound"
import { DialogBox } from "lib/@components/dialogBox"
import { Box, Chip, Stack, Typography } from "@mui/material"
import { BoundTextEditor } from "minimals-template/components/editor"
import { ListItemBox } from "lib/@components/ListItemBox"
import { ScrollIntoView } from "lib/@components/scrollIntoView"
import { htmlToText } from "lib/html-to-text"
import { UploadFileButton } from "lib/@components/upload-file-button"

export function ReportDialog({ title }) {
    const [state] = useState({})
    const refresh = useRefresh()
    const [attachments, setAttachments] = useState([])
    state.attachments = attachments

    return (
        <Bound target={state} refresh={refresh} onChange={refresh}>
            <DialogBox
                sx={{ height: "100%" }}
                title="Report Problem"
                value={state}
                okDisabled={!htmlToText(state.report).trim()}
            >
                <Stack spacing={1}>
                    <Box pb={4} />
                    <Stack spacing={1}>{title}</Stack>
                    <Typography gutterBottom component="div" sx={{ py: 2 }}>
                        Please clearly document the problem and attach any files or pictures that will help in resolving
                        the issue.
                    </Typography>
                    <BoundTextEditor
                        autoFocusss
                        className="contactUsMessageEditor"
                        simple={true}
                        sx={{ flex: 1, "& .jodit-workplace": { height: "200px !important" } }}
                        field="report"
                        label=""
                    />
                    <Stack direction="row">
                        <Box flex={1} />
                        <UploadFileButton sx={{ minWidth: "max-content" }} onClick={handleAttachFile}>
                            Attach File
                        </UploadFileButton>
                    </Stack>
                    <ListItemBox>
                        {attachments.length > 0 && (
                            <Stack spacing={1}>
                                <Typography variant="caption">Attachments ({attachments.length}):</Typography>
                                {attachments.map((attachment) => (
                                    <Fragment key={attachment.url}>
                                        <Chip
                                            sx={{ ml: 1, justifyContent: "space-between", width: "250px" }}
                                            onDelete={() => handleDeleteAttachment(attachment)}
                                            label={attachment.name}
                                            title={attachment.name}
                                        />
                                        <ScrollIntoView />
                                    </Fragment>
                                ))}
                            </Stack>
                        )}
                    </ListItemBox>
                    <Box flex={1} />
                </Stack>
            </DialogBox>
        </Bound>
    )

    function handleAttachFile(url, file) {
        setAttachments((attachments) => [...attachments, { url, name: file.name }].unique("url"))
    }

    function handleDeleteAttachment(attachment) {
        setAttachments((attachments) => attachments.filter((a) => a !== attachment))
    }
}
