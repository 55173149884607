export function createSortFunction(...fields) {
    return function customSort(a, b) {
        // Define the fields and order by which to sort
        for (let field of fields) {
            if (Array.isArray(field(a)) && Array.isArray(field(b))) {
                // If both fields are arrays, compare their elements
                for (let i = 0; i < Math.min(field(a).length ?? 0, field(b).length ?? 0); i++) {
                    if (field(a)[i] < field(b)[i]) return -1
                    if (field(a)[i] > field(b)[i]) return 1
                }
            } else {
                // If fields are not arrays, just compare the fields directly
                if (field(a) < field(b)) return -1
                if (field(a) > field(b)) return 1
            }
        }
        return 0
    }
}
