import { TaskHeader } from "routes/schedule/tasks/taskHeader"
import { Task } from "routes/schedule/tasks/task"

export function TaskOrHeader({ item, index, ...props }) {
    if (!item) return null
    return item.isHeader ? (
        <TaskHeader {...props} item={item} index={index} />
    ) : (
        <Task {...props} item={item} index={index} />
    )
}
