import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const getIntroductions = api(async () =>
    query(
        gql`
            query getIntroductions {
                introductions
            }
        `,
        {},
        { returns: "introductions" }
    )
)
