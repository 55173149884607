import { Bound } from "lib/@components/binding/Bound"
import { Stack } from "@mui/material"
import { ScheduleCompanionDetails } from "companion/routes/schedule/schedule-companion-details"
import { NavigateTask } from "event-definitions"

export function ScheduleAndTaskRender({ schedule, taskId }) {
    taskId = taskId.split(".")
    return (
        !!schedule && (
            <Bound hideUpBar target={schedule} schedule={schedule} taskId={taskId.join(".")}>
                <Stack spacing={1} sx={{ px: 1 }}>
                    <ScheduleCompanionDetails rendered={showTask} />
                </Stack>
            </Bound>
        )
    )

    function showTask() {
        const index = schedule.tasks.findIndex((t) => t.id.split(".").every((v, i) => taskId[i] === v))
        if (index !== -1) {
            NavigateTask.raise(index, true)
        }
        setTimeout(() => {
            const index = schedule.tasks.findIndex((t) => t.id.split(".").every((v, i) => taskId[i] === v))
            if (index !== -1) {
                NavigateTask.raise(index, true)
            }
        }, 250)
    }
}
