import { getMyShareLinks } from "routes/shared-with-me/controller/get-my-share-links"
import { Grid } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import { LinkCard } from "routes/shared-with-me/link-card"
import { invalidate } from "lib/graphql/cache"
import { SharingLinkUpdated } from "event-definitions"
import { SearchBoxUsingSearchParam } from "lib/@components/searchBoxUsingSearchParam"
import { useSearchParam } from "lib/@hooks/use-search-param"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { DisplayError } from "lib/@components/display-error"
import { NoResults } from "lib/@components/no-results/no-results"
import { MaintenanceIllustration } from "minimals-template/assets"

SharingLinkUpdated.handleOnce((users) => {
    if (users.some((user) => window._authorization?.user?.email?.endsWith(user))) {
        invalidate("getMyShareLinks")
    }
})

export function Links() {
    const [search] = useSearchParam("search", "")
    const { loading, error, data: links } = getMyShareLinks.useResults.status(search)

    if (loading) return <LoadingScreen description={"Shared links"} />
    if (error)
        return (
            <DisplayError
                errorHeader={"Client data unavailable"}
                errorMessage={"Unable to load client!"}
                illustration={MaintenanceIllustration}
            />
        )

    if (!links) return <NoResults />
    return (
        <>
            <SearchBoxUsingSearchParam placeholder="Filter..." />
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Repeat list={links?.links} item={<LinkCard />} />
            </Grid>
        </>
    )
}
