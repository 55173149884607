import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const areTasksComplete = api(async (shareLink, onDate, tasks) =>
    query(
        gql`
            query API_AreTasksComplete($shareLink: String!, $onDate: Date, $tasks: [TaskQuery!]!) {
                areTasksComplete(shareLinkId: $shareLink, onDate: $onDate, tasks: $tasks)
            }
        `,
        { shareLink, onDate, tasks },
        { returns: "areTasksComplete" }
    )
)
