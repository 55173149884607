import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { List } from "@mui/material"

import { DragEnded, DragStarted } from "event-definitions"
import { arrayMoveInPlace } from "lib/ArrayMoveInPlace"
import { Bound } from "lib/@components/binding/Bound"
import { DragContext } from "lib/DragContext"
import { useState } from "react"
import { createPortal } from "react-dom"
import { noop } from "lib/noop"

export function Sortable({
    items,
    id = "id",
    children,
    onDragEnd = noop,
    onOrdered = noop,
    strategy = verticalListSortingStrategy,
}) {
    const [overlay, setDragOverlay] = useState(null)
    const [context] = useState({})
    context.setDragOverlay = setDragOverlay
    const sensors = useSensors(
        useSensor(PointerSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(KeyboardSensor),
        useSensor(MouseSensor)
    )

    const sortIds = items.map(getSortId)
    const check = sortIds.unique()
    if (check.length !== sortIds.length) {
        console.error("Duplicate IDs in Sort")
    }

    return (
        <Bound sortItems={items}>
            <DragContext.Provider value={context}>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                >
                    <SortableContext items={sortIds} strategy={strategy}>
                        {children}
                    </SortableContext>
                    {createPortal(
                        <DragOverlay dropAnimation={null}>{overlay && <List>{overlay}</List>}</DragOverlay>,
                        document.body
                    )}
                </DndContext>
            </DragContext.Provider>
        </Bound>
    )

    function handleDragEnd({ active, over }) {
        context.active = null
        setDragOverlay(null)
        DragEnded.raise()
        if (active.id !== over.id) {
            if (onDragEnd({ active, over }) !== false) {
                arrayMoveInPlace(
                    items,
                    items.findIndex((i) => getSortId(i) === active.id),
                    items.findIndex((i) => getSortId(i) === over.id)
                )
                onOrdered(items)
            }
        }
    }

    function handleDragStart({ active }) {
        context.active = active
        DragStarted.raise()
    }

    function getSortId(item) {
        if (id && typeof item === "object") {
            return `sort_${item[id]}`
        }
        return `sort_${item}`
    }
}
