import useAsync from "lib/@hooks/useAsync"
import storage from "localforage"
import { regime } from "companion/routes/schedule/controller/regime"
import { regimeGuid } from "companion/routes/schedule/controller/regime-guid"
import { delay } from "lib/delay"

const TASK_KEY = "_TASK_"
export const Loading = Symbol("Loading")

export function useTasks(shareLinkId, minimumInterval, maximumInterval, refId) {
    return useAsync(getTasks, [], refId) ?? { [Loading]: true }

    async function getTasks(update) {
        let retry = true
        do {
            const now = Date.now()
            const taskDateKey = `${TASK_KEY}@@@task-date.${shareLinkId}`
            const tasksKey = `${TASK_KEY}@@@task-existing.${shareLinkId}`
            const taskGuid = `${TASK_KEY}@task-guid.${shareLinkId}`
            const oldGuid = await storage.getItem(taskGuid)
            const newGuid = await regimeGuid(shareLinkId)

            if (oldGuid !== newGuid) {
                console.log("Getting new tasks")
                await storage.setItem(taskGuid, newGuid)
                await storage.removeItem(taskDateKey)
                await storage.removeItem(tasksKey)
            }

            let since = await storage.getItem(taskDateKey)
            const existingTasks = (await storage.getItem(tasksKey)) ?? {}
            if (Object.isEmpty(existingTasks)) since = undefined
            update({ ...existingTasks, [Loading]: since === undefined })

            try {
                const newTasks = await regime(shareLinkId, since, minimumInterval, maximumInterval)

                for (const task of newTasks) {
                    if (task.__typename === "APITask") {
                        task._where = task.where.split(" › ").map((s) => s.trim())
                        existingTasks[task.id] = task
                    } else {
                        delete existingTasks[task.id]
                    }
                }

                await storage.setItem(taskDateKey, now)
                await storage.setItem(tasksKey, existingTasks)
                retry = false
                const returnTasks = { ...existingTasks }
                update(returnTasks)
                return returnTasks
            } catch (e) {
                console.error(e)
                await delay(1000 * 60 * 5)
            }
        } while (retry)
        return []
    }
}
